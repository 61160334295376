<!-- 商戶信息 列表页面 -->
<template>
    <div class="mt-4 mx-4">
        <ath-breadcrumbs :items="navigationItems" flat="false" txtColor="" bgColor=""/>
        <div style="margin-top:80px;margin-bottom:50px; width:25%; margin-left: 35%;">
            <div>
                <v-form class="ath-form-readonly" :rules="rules" ref="detailForm" style="margin: 0 10px; " autocomplete="off">
                <v-row>
                    <v-col>
                        <ath-input-text
                            :label="this.$t('userInfo.userName')"
                            v-model="userInfo.userName"
                            maxlength="15"
                            readonly="true"
                            />
                    </v-col>
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div>
                        <ath-input-text
                            :label="this.$t('userInfo.oldPwd')"
                            v-model="userInfo.oldPwd"
                            :type="oldPasswordEyes?'password':'text'" 
                            :rules="rules.oldPwd" 
                            maxlength="12"
                            />
                        <span style="float: right; margin-top:-50px;" @click="showOldPasswordEyes">
                            <v-icon>{{ oldPasswordEyes?'mdi-eye-off':'mdi-eye' }}</v-icon>
                        </span>
                    </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                            <div>
                        <ath-input-text
                            :label="this.$t('userInfo.newPwd')"
                            v-model="userInfo.newPwd"
                            :type="newPasswordEyes?'password':'text'" 
                            :rules="rules.newPwd" 
                            maxlength="12"
                            />
                        <span style="float: right; margin-top:-50px;" @click="showNewPasswordEyes">
                            <v-icon>{{ newPasswordEyes?'mdi-eye-off':'mdi-eye' }}</v-icon>
                        </span>
                    </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                            <div>
                        <ath-input-text
                            :label="this.$t('userInfo.confirmPwd')"
                            v-model="userInfo.confirmPwd"
                            :type="confirmPasswordEyes?'password':'text'" 
                            :rules="rules.confirmPwd" 
                            maxlength="12"
                            />
                        <span style="float: right; margin-top:-50px;" @click="showConfirmPasswordEyes">
                            <v-icon>{{ confirmPasswordEyes?'mdi-eye-off':'mdi-eye' }}</v-icon>
                        </span>
                    </div>
                    </v-col>
                </v-row>
                <v-row style="margin-left:35%;">
                    <v-col>
                        <ath-button class="primary" :text="this.$t('action.confirm')" @click="updatePwd" icon="mdi-checkbox-marked-circle-outline" />
                    </v-col>
                </v-row>
                </v-form>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'UserInfo',
    props: {},
    data() {
        return {
            navigationItems: [
                {text:this.$t('menu.userInfo'),disabled:true,to:''}
            ],
            headers: [],
            items: [],
            total: 0,
            options: '',
            index:0,
            dTableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                multiSort: false,
                mustSort: false,
            },
            userInfo:{},
            salt:"",
            randomText: "",
            modulus: "",
            publicExponent: "",
            alg: "",
            environment: '',
            domain: '',
            rules:{
                oldPwd:[
                    v => !!v || this.$t('msg.pleaseEnterRequiredField')
                ],
                newPwd:[
                    v => !!v || this.$t('msg.pleaseEnterRequiredField'),
                    v => !!this.checkPwd(v) || this.$t('userInfo.msg.pwdRule'),
                    v => !!this.checkNewPwd(v) || this.$t('userInfo.msg.newPwdEquelPwd'),
                ],
                confirmPwd:[
                    v => !!v || this.$t('msg.pleaseEnterRequiredField'),
                    v => !!this.checkPwd(v) || this.$t('userInfo.msg.pwdRule'),
                    v => !!this.checkConfirmPwd(v) || this.$t('userInfo.msg.pwdIsNotEquel'),
                ],
            },
            oldPasswordEyes: true,
            newPasswordEyes: true,
            confirmPasswordEyes: true,
        };
    },
    watch: {
        '$athlon.i18n.locale': {
            handler(newValue) {
                this.navigationItems = [
                    {text:this.$t('menu.userInfo'),disabled:true,to:''}
                ];
            },
            deep: true
        }
    },
    computed: {

    },
    created() {
        const userdata = this.$athlon.getUserData();
        this.userInfo={
            "userName": userdata.name,
            "oldPwd":"",
            "newPwd":"",
            "confirmPwd":""
        };
        this.init();
    },

    methods: {
        init(){
			//获取登录所需参数以及是否需要登录验证
			this.$loading(this.$t('msg.loading'));
			const params = {
				random: new Date(),
			};
			this.$athlon.request.post('login/getEnvironmentInfo', params, this.$athlon.config.api.requestParamheaders).then((res) => {
				if(this.dealGlobalResponse(res)) return;
				this.salt = res.data.salt;
				this.randomText = res.data.randomText;
				this.modulus = res.data.modulus;
				this.alg = res.data.alg;
                this.publicExponent = res.data.publicExponent;
                this.environment = res.data.environment;
				this.domain = res.data.domain;
				this.$loading(false);
			}).catch(() => {
				this.$loading(false);
				this.$router.push('/error/500');
			});
		},
        checkPwd(v){
            if(v == '')return true;
            var regular = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]{8,12}$/ ;
            if(regular.test(v)) {
                return true;
            }
            return false;
        },
        checkNewPwd(v){
            if(v == '')return true;
            if(this.userInfo.oldPwd === v){
              return false;
            }
            return true;
        },
        checkConfirmPwd(v){
          if(this.userInfo.newPwd === v){
              return true;
          }
          return false;
        },
        updatePwd(){
            const result = this.$refs.detailForm.validate();
            if(!result)return;
            this.$loading(this.$t('msg.loading'));
            let params = {
                oldPwd: this.encryptForServer(this.userInfo.oldPwd),
                newPwd: this.encryptForServer(this.userInfo.newPwd),
                randomText: this.randomText
            }
            this.$athlon.request
            .post('userInfo/updatePwd', params, this.$athlon.config.api.requestParamheaders)
            .then((res) => {
                if(res.data != null){
                    if(res.data.code == 0){
                        this.tipsMsg(this.$t('msg.editSuccess'));
                        this.$athlon.request.post('logout/signoff').then((res) => {
                            this.$loading(false);
                            this.$router.push({
                                path: '/login'
                            });
                        }).catch(() => {
                            this.$loading(false);
                            this.tipsMsg(this.$t('msg.systemError'));
                        });
                    }else{
                        this.tipsMsg(this.$t('userInfo.msg.oldPwdError'));
                    }
                }
                this.$loading(false);
            })
            .catch((err) => {
                this.$loading(false);
                this.$snackbar(this.$t('error.500')+`：${err.message}`);
            });
        },
        encryptForServer(password) {//前端js方式加密密碼
            /* eslint-disable */
            let hashText = this.hash(this.salt + this.hash(password)); // 计算密码的哈希值

            let plainText = this.randomText + hashText;

            let rsaKeylen = 2048; // 密钥和密文长度
            setMaxDigits(rsaKeylen/8 + 1);// 外部js文件中的方法

            var rsakey = new RSAKeyPair(this.publicExponent, this.publicExponent, this.modulus, rsaKeylen); // 构造RSA密钥对象,外部js文件中的方法

            var cipherText = encryptedString(rsakey, plainText, this.alg).toUpperCase(); // 加密

            /* eslint-disable */
            return cipherText;
        },
        hash(input) {//計算字符串的hash值 sha 256方式
			/* eslint-disable */
			var value = "" + CryptoJS.SHA256(input);
			/* eslint-disable */
			return value.toUpperCase();
        },
        showOldPasswordEyes(){
            if(this.oldPasswordEyes==true){
                this.oldPasswordEyes=false;
            }else{
                this.oldPasswordEyes=true;
            }
        },
        showNewPasswordEyes(){
            if(this.newPasswordEyes==true){
                this.newPasswordEyes=false;
            }else{
                this.newPasswordEyes=true;
            }
        },
        showConfirmPasswordEyes(){
            if(this.confirmPasswordEyes==true){
                this.confirmPasswordEyes=false;
            }else{
                this.confirmPasswordEyes=true;
            }
        },
    },

};
</script>
<style scoped>
    .stat {
		margin: 0px 12px 0px 12px;
    }

	.my-items .my-item:first-child {
		border-top: #000 1px solid;
	}

	.my-item {
		border-bottom: #000 1px solid;
		font: 16px sans-serif;
		min-width: 1000px;
	}

	.my-item-label {
		text-align: left;
		font-weight: 500px;
		width: 25%;
		padding: 12px;
		border-left: #000 1px solid;
	}

	.my-item-label-color {
		background-color: #f4faff;
		text-align: right;
		font-weight: 500px;
		width: 25%;
		padding: 12px;
		border-left: #000 1px solid;
	}

	.my-item .my-item-label:last-child {
		border-right: #000 1px solid;
	}

	.my-item-head{
		text-align: center;
		font-size: 1.25rem;
		font-weight: 500;
		background-color: #d9e8f5;
		line-height: 50px;
		height: 50px;
		border: #000 1px solid;
		border-bottom: 0px;
	}

</style>
